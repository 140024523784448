module.exports = {
  siteTitle: 'Medical Excellence', // <title>
  manifestName: 'Grayscale',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/favicon.png',
  pathPrefix: `/gatsby-starter-grayscale/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'Medical Excellence',
  subHeading: 'Research & Care for the Healthier Life\n"임상연구부터 관리까지,건강한 미래를 위하여"',
  aboutTitle: '"메디칼엑셀런스"',
  aboutDesc: '지식과 기술로 사람과 의료 사이에 새로운 가치를 더하는 의료서비스 전문 기업을 꿈꾸며 임상연구에서 부터 실제 질병과 건강을 관리해 주는 서비스 영역까지 오로지 건강한 미래를 위하여 노력하고 있습니다.\n2006년 설립 후 끊임 없는 연구 개발을 계속해 왔고, 임상시험 수탁 서비스, 임상 빅데이터 분석, 디지털헬스케어, 만성질환 건강관리 서비스 분야의 경험과 기술을 보유하고 있습니다.',

  serviceTitle1: 'Healthcare',
  serviceDesc1: '메디칼엑셀런스는 가톨릭대학교 의과대학 임상연구에 근거한 디지털 기반 당뇨병 관리 시스템 “아이케어디”를 비롯, 만성질환 관리 프로그램을 연구 개발하고 있습니다. 환자 스스로 주도적으로 질환을 관리하고, 의료진에게는 환자의 일상 데이터를 분석, 의미를 추출하여 임상적 판단을 도와주며, 환자에게 끊김없는 관리를 제공하여, 만성질환자들이 더욱 건강한 삶을 누릴 수 있는 미래를 가능하게 하도록 노력하고 있습니다.',

  serviceTitle2: 'CRO',
  serviceDesc2: '메디칼엑셀런스는 2006년 설립 후 의약품, 의료기기 등 다양한 임상 분야에서 임상시험과 연구를 수행하였습니다. 축적된 임상 데이터 관리와 분석 경험도 가지고 있습니다. 빅 데이터 시대에 임상 빅데이터 분석을 선도하고 있습니다.\n\n질 높은 임상 연구를 목표로 연구자와 헬스케어 기업의 최고의 파트너가 되고자 노력하고 있습니다.',

  careerTitle: `
    메디칼엑셀런스 사람들은\n
    우리의 핵심가치 “사람에 대한 이해와 사랑”, “전문성”, “협력”을 실현하기 위하여\n
    1. 모두가 회사의 대표로, 정직성을 가지고 책임을 다합니다.\n
    2. 모두가 최고가 되기 위해 끊임없이 노력합니다.\n
    3. 모두가 동료, 고객, 사회와 같이하는 행복한 삶을 추구합니다.\n
    메디칼엑셀런스는 도전을 함께할 열정 가득한 동료를 기다리고 있습니다.\n
    지금 바로 지원하세요!
  `,
  
  // social
  socialLinks: [
    {
      icon: 'fa-github',
      name: 'Github',
      url: 'https://github.com/anubhavsrivastava',
    },
    {
      icon: 'fa-linkedin-in',
      name: 'Linkedin',
      url: 'https://linkedin.com/in/theanubhav/',
    },
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/onlyanubhav',
    },
    {
      icon: 'fa-facebook-f',
      name: 'Facebook',
      url: 'https://facebook.com/theanubhav',
    },
  ],
  email: 'mediex@mediex.co.kr',
  phone: '02-596-2704',
  fax: '02-6209-7800',
  address: '서울시 서초구 반포대로 222 서울성모병원 별관4층',
};
